<template>
  <TepmplateBlock>
    <div class="row start-block__wrap">
      <div class="logo mb-5">
        <img
          src="../resource/img/logo_aura_eng.svg"
          alt="logo"
          class="logo__img"
        />
      </div>
      <div class="start-block__btn-wrap">
        <router-link to="/auth" class="btn mb-2">Вход</router-link>
        <router-link to="/registration" class="btn btn-primary"
          >Регистрация новой компании</router-link
        >
      </div>
    </div>
  </TepmplateBlock>
</template>

<script>
// @ is an alias to /src

import TepmplateBlock from "../components/TepmplateBlock";
import { mapGetters } from "vuex";
export default {
  name: "Home",
  components: {
    TepmplateBlock
  },
  computed: {
    ...mapGetters(["getAuth"])
  },
  mounted() {
    if (this.getAuth.isAuth && this.getAuth.role !== 0) {
      this.$router.push({ name: "Main" });
    }
  }
};
</script>

<style scoped lang="scss">
.logo {
  display: block;
  width: 215px;
  height: auto;
}

.start-block__wrap {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.start-block__btn-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.mb-2:hover {
  background-color: var(--main-text-color-inverse);
  border: 2px solid var(--main-text-color-inverse);
}
.btn-primary:hover {
  background-color: var(--main-text-color-inverse);
  color: var(--main-text-color);
  border: 2px solid var(--main-text-color-inverse);
}
</style>
